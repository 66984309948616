import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <div className='footer'>
      @2024 Voters Choice
    </div>
  )
}

export default Footer
