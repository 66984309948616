export const KZN= [
    {
        id: 0,
        title: "Abantu Batho Congress (ABC)",
        icon: "./Assets/Images/Party Logo/ABC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 1,
        title: <a href="https://www.actionsa.org.za//">Action SA()</a>,
        icon: "./Assets/Images/Party Logo/ASA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 2,
        title: <a href="https://ara-sa.org.za/">Africa Restoration Alliance (ARA)</a>,
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 3,
        title: <a href="https://acdp.org.za/">African Christian Democratic Party (ACDP)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 4,
        title: "African Independent Congress (AIC)",
        icon: "./Assets/Images/Party Logo/AIC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 5,
        title: "African Movement Congress",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 6,
        title: <a href="https://www.anc1912.org.za/">African National Congress (ANC)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 7,
        title: "African People First (APF)",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 8,
        title: "African Peoples Movement (APM)",
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 9,
        title: <a href="http://www.atm-sa.co.za/">African Transfomation Movement (ATM)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 10,
        title: <a href="https://www.aljama.co.za/">Al-Jama-ah (AJA)</a>,
        icon: "./Assets/Images/Party Logo/ALJ.jpg",
        date: "02/03/2024",
        responsibility: [""],
    },
    {
        id: 11,
        title: "All Citizens Party",
        icon: "./Assets/Images/Party Logo/ACP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 12,
        title: <a href="https://am4c.org/">Allied Movement for Change (AM4C)</a>,
        icon: "./Assets/Images/Party Logo/AM4C.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 13,
        title: <a href="https://www.arisesa.org/">Arise South Africa</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 14,
        title: <a href="https://www.bosa.co.za/">Build One South Africa (BOSA)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 15,
        title: <a href="https://www.copesa.org.za/">Congress of the People (COPE)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 16,
        title: <a href="https://www.da.org.za/">Democratic Alliance (DA)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 17,
        title: <a href="https://dlc.org.za/">Democratic Liberal Congress (DLC)</a>,
        icon: "./Assets/Images/Party Logo/DLC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 18,
        title: <a href="https://effonline.org/">Economic Freedom Fighters (EFF)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 19,
        title: <a href="https://www.elfonline.org/">Economic Liberators Forum South Africa (ELF-SA)</a>,
        icon: "./Assets/Images/Party Logo/ELF-SA.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 20,
        title: <a href="https://forgood.org.za/">GOOD</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 21,
        title: <a href="https://www.ifp.org.za/">Inkatha Freedom Party (IFP)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    }, 
    {
        id: 22,
        title: "Justice and Employment Party",
        icon: "./Assets/Images/Party Logo/AHC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 23,
        title: "Thanasagren Moodley",
        icon: "./Assets/Images/Party Logo/AHC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 24,
        title: "National Freedom Party (NFP)",
        icon: "./Assets/Images/Party Logo/NFP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 25,
        title: <a href="https://pac.org.za/">Pan Africanist Congress of Azania (PAC)</a>,
        icon: "./Assets/Images/Party Logo/PAC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 26,
        title: <a href="https://mypa.org.za/">Patriotic Alliance (PA)</a>,
        icon: "./Assets/Images/Party Logo/PA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 27,
        title: "Peoples Freedom Party",
        icon: "./Assets/Images/Party Logo/ACP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 28,
        title: <a href="https://www.risemzansi.org/">Rise Mzansi (RS)</a>,
        icon: "./Assets/Images/Party Logo/Rise-Mzansi.png",
        date: "08/02/2024",
        responsibility: [""],
    },
    {
        id: 29,
        title: <a href="https://sizweummahnation.wordpress.com/">Sizwe Ummah Nation (SUN)</a>,
        icon: "./Assets/Images/Party Logo/SUN.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 30,
        title: <a href="https://mkparty.org.za/">uMkhonto weSizwe (MK)</a>,
        icon: "./Assets/Images/Party Logo/MK.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 31,
        title: <a href="https://udm.org.za/">United Democratic Movement (UDM)</a>,
        icon: "./Assets/Images/Party Logo/UDM.png",
        date: "02/03/2024",
        responsibility: [""],
    },
    {
        id: 32,
        title: <a href="https://www.vfplus.org.za/">Freedom Front Plus (FF+)</a>,
        icon: "./Assets/Images/Party Logo/FF+.png",
        date: "02/03/2024",
        responsibility: [""],
    },
]

export const kwa_zulu = KZN.map(data => 
    <li key={data.id}>
      <p>{data.title}</p>
    </li>
  )