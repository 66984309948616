export const EASTERN_CAPE= [
    {
        id: 0,    
        title: <a href="https://www.actionsa.org.za//">Action SA()</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 1,
        title: <a href="https://acdp.org.za/">African Christian Democratic Party (ACDP)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 2,
        title: "African Congress for Transformation",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 3,
        title: "African Independent Congress",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 4,
        title: "African Movement Congress",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 5,
        title: <a href="https://www.anc1912.org.za/">African National Congress (ANC)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 6,
        title: "African Peoples Convention",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 7,
        title: <a href="http://www.atm-sa.co.za/">African Transfomation Movement (ATM)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 8,
        title: <a href="https://www.aljama.co.za/">Al-Jama-ah (AJA)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 9,
        title: "Alliance of Citizens for Change",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 10,
        title: <a href="https://www.arisesa.org/">Arise South Africa</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 11,
        title: <a href="https://azapo.org.za/">Azanian Peoples Organization (AZAPO)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 12,
        title: "Batho Pele Movement",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 13,
        title: <a href="https://www.bosa.co.za/">Build One South Africa (BOSA)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 14,
        title: <a href="https://www.copesa.org.za/">Congress of the People (COPE)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 15,
        title: <a href="https://www.da.org.za/">Democratic Alliance (DA)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 16,
        title: <a href="https://effonline.org/">Economic Freedom Fighters (EFF)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 17,
        title: <a href="https://forgood.org.za/">GOOD</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 18,
        title: <a href="https://isanco.africa/">Independent South African National Civic Organization (ISANCO)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 19,
        title: <a href="https://www.ifp.org.za/">Inkatha Freedom Party (IFP)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    }, 
    {
        id: 20,
        title: "Komani Progress Action",
        icon: "./Assets/Images/Party Logo/AIC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 21,
        title: <a href="https://pac.org.za/">Pan Africanist Congress of Azania (PAC)</a>,
        icon: "./Assets/Images/Party Logo/PAC.png",
        date: "15/03/2024",
        responsibility: ["The Pan Africanist Congress of Azania (PAC) has released its manifesto for the 2024 national and provincial elections in South Africa. The manifesto focuses on building a democratic state, achieving total liberation, and restoring the land to its original owners. The PAC is committed to complying with the Basic Documents, including the Constitution, Disciplinary Code, and the 1959 Africanist Manifesto. They are contesting for power and influence in the running of the country and are sticking to the concrete ideas of Sobukwe and APMda. The 2024 Manifesto is an outcome of collective work by various leaders at national, provincial, regional, and branch levels. The PANDA's development agenda includes focusing on economic growth, gender equality, national security, border management, agriculture, education, health, social development, transportation, sports, arts, culture, and more. The Manifesto aims to help the liberation movement to speak with one voice and achieve total liberation."],
    },
    {
        id: 22,
        title: <a href="https://mypa.org.za/">Patriotic Alliance (PA)</a>,
        icon: "./Assets/Images/Party Logo/PA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 23,
        title: <a href="https://www.risemzansi.org/">Rise Mzansi (RS)</a>,
        icon: "./Assets/Images/Party Logo/Rise-Mzansi.png",
        date: "08/02/2024",
        responsibility: ["The People’s Manifesto Booklet for South Africa is being launched on 20 January 2024. The manifesto focuses on policies such as leadership, governance, economy and jobs, individual and family well-being, and managing the climate crisis. The National Leadership Collective (NLC) of RISE Mzansi wishes to express gratitude to the thousands of people who attended their community meetings and made their voices heard. The goal of the manifesto is to ensure that all South Africans have access to the same rights, entitlements, and opportunities to create a united, equal, and prosperous society. The governing ANC has run out of ideas, goodwill, and steam, and needs people who will choose to lead ethically and professionally for the common good. The NLC will publish the names of candidates who represent South Africa in all its facets, have a track record, skills, and experience."],
    },
    {
        id: 24,
        title: <a href="https://mkparty.org.za/">uMkhonto weSizwe (MK)</a>,
        icon: "./Assets/Images/Party Logo/MK.png",
        date: "15/03/2024",
        responsibility: ["The MK Party manifesto outlines their commitment to bringing transformative change to South African society, addressing issues such as economic inequality, access to education and healthcare, national security, land distribution, traditional leadership, and foreign policy. They aim to extend the electrical grid, prioritize sustainable electricity, align education with market demands, enhance skills transfer, strengthen border security, overhaul law enforcement, accelerate land redistribution, protect resources, improve healthcare access and quality, create jobs, promote economic growth, empower traditional leaders, advocate for regional stability, and assert South Africa's interests on the international stage. The manifesto reflects the party's vision for a more equitable, secure, and prosperous future for all South Africans through collaborative efforts, sound governance, and commitment to their principles."],
    },
    {
        id: 25,
        title: <a href="https://udm.org.za/">United Democratic Movement (UDM)</a>,
        icon: "./Assets/Images/Party Logo/UDM.png",
        date: "02/03/2024",
        responsibility: ["The UDM 2024 manifesto for the National and Provincial Elections in South Africa highlights the need for good governance, investment in business confidence, sustainable job creation, and protecting the environment. The country is in crisis due to joblessness, crime, lawlessness, violence, poor economy, loadshedding, water, poverty, incompetence, and corruption. The ruling party has been implicated in corruption scandals, and the Tripartite Alliance partners have not delivered on their economic policies. The manifesto also highlights the role of traditional leaders, faith-based organizations, international relations, diversity, conservation, sustainability, safety, security, justice, defense, LGBTQIA+ community, education, healthcare, and marginalized stakeholders. The President warns that the ruling party cannot be trusted to find solutions to corruption as they have their hands in the proverbial cookie jar."],
    },
    {
        id: 26,
        title: <a href="https://www.vfplus.org.za/">Freedom Front Plus (FF+)</a>,
        icon: "./Assets/Images/Party Logo/FF+.png",
        date: "02/03/2024",
        responsibility: ["The building plan for South Africa offers solutions for building a better future, including multi-party governments, a free-market system, small, central and local governments, independent communities, and promoting communities' constitutional right to self-determination. The country is currently in decline due to political centralization, economic over-regulation, and environmental damage caused by the ANC's failed policy. The plan emphasizes the need for a better dispensation, not just a better government, and to restore the country's infrastructure and service. The focus of the plan is on restoring a new dispensation that prioritizes diversity, economic empowerment, and sustainable development and environmental sustainability. The National Democratic Revolution (NDR) and Black Economic Empowerment (BEE) are key to achieving this. South Africa needs to make a positive turning point in order to turn around its decline and restore its economy, infrastructure, and services. It will take years of hard work to undo the legacy of corruption and turn the country around."],
    },
    {
        id: 3,
        title: <a href="https://www.azania2024.org.za/">Azania Peaceful Revolution</a>,
        icon: "./Assets/Images/Party Logo/AADP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 4,
        title: "Basic Income Grant SA",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 5,
        title: <a href="https://bpmsa.org.za/bpm-manifestos/">Batho Pele Movement (BPM)</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 6,
        title: "Bolsheviks Party of South Africa",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 7,
        title: "Independent",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 9,
        title: "Justice and Employment Party",
        icon: "./Assets/Images/Party Logo/AHC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
  
    {
        id: 11,
        title: "Land Party",
        icon: "./Assets/Images/Party Logo/AMC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 12,
        title: "National Independent Party",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 13,
        title: "Operation Dudula",
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 14,
        title: "Peoples Freedom Party",
        icon: "./Assets/Images/Party Logo/ACP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 15,
        title: "Sindawonye Progressive Party",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 16,
        title: "Socialist Agenda of Dispossesed Africans",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 17,
        title: "South African Maintenance and Estate Beneficiaries Association",
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    
    {
        id: 18,
        title: <a href="https://sayparty.org/">South African Youth Power Party</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
]

export const easternCape = EASTERN_CAPE.map(data => 
    <li key={data.id}>
      <p>{data.title}</p>
    </li>
  )