export const WESTERN_CAPE= [
    {
        id: 0,
        title: <a href="https://www.actionsa.org.za//">Action SA()</a>,
        icon: "./Assets/Images/Party Logo/ASA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 1,
        title: <a href="https://ara-sa.org.za/">Africa Restoration Alliance (ARA)</a>,
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 2,
        title: <a href="https://acdp.org.za/">African Christian Democratic Party (ACDP)</a>,
        icon: "./Assets/Images/Party Logo/ACDP.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 3,
        title: "African Independent Congress (AIC)",
        icon: "./Assets/Images/Party Logo/AIC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 4,
        title: <a href="https://www.anc1912.org.za/">African National Congress (ANC)</a>,
        icon: "./Assets/Images/Party Logo/ANC.png",
        date: "24/02/2024",
        responsibility: [""],
    },
    {
        id: 5,
        title: <a href="http://www.atm-sa.co.za/">African Transfomation Movement (ATM)</a>,
        icon: "./Assets/Images/Party Logo/ATM.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 6,
        title: <a href="https://www.aljama.co.za/">Al-Jama-ah (AJA)</a>,
        icon: "./Assets/Images/Party Logo/ALJ.jpg",
        date: "02/03/2024",
        responsibility: [""],
    },
    {
        id: 7,
        title: <a href="https://accforchange.org.za/">Alliance of Citizens For Change</a>,
        icon: "./Assets/Images/Party Logo/A.C.C.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 8,
        title: <a href="https://am4c.org/">Allied Movement for Change (AM4C)</a>,
        icon: "./Assets/Images/Party Logo/AM4C.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 9,
        title: <a href="https://www.arisesa.org/">Arise South Africa</a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 10,
        title: <a href="https://www.azania2024.org.za/">Azania Peaceful Revolution</a>,
        icon: "./Assets/Images/Party Logo/AADP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 11,
        title: <a href="https://www.bosa.co.za/">Build One South Africa (BOSA)</a>,
        icon: "./Assets/Images/Party Logo/BOSA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 12,
        title: <a href="https://www.copesa.org.za/">Congress of the People (COPE)</a>,
        icon: "./Assets/Images/Party Logo/COPE.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 13,
        title: <a href="https://www.da.org.za/">Democratic Alliance (DA)</a>,
        icon: "./Assets/Images/Party Logo/DA.png",
        date: "17/02/2024",
        responsibility: [""],
    },
    {
        id: 14,
        title: <a href="https://effonline.org/">Economic Freedom Fighters (EFF)</a>,
        icon: "./Assets/Images/Party Logo/EFF.png",
        date: "08/02/2024",
        responsibility: [""],
    },
    {
        id: 15,
        title: <a href="https://forgood.org.za/">GOOD</a>,
        icon: "./Assets/Images/Party Logo/GOOD.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 16,
        title: <a href="https://www.ifp.org.za/">Inkatha Freedom Party (IFP)</a>,
        icon: "./Assets/Images/Party Logo/IFP.png",
        date: "02/03/2024",
        responsibility: [""],
    }, 
    {
        id: 17,
        title: "Land Party",
        icon: "./Assets/Images/Party Logo/AMC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 18,
        title: <a href="https://nationalcolouredcongress.org.za/">National Coloured Congress (NCC)</a>, 
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 19,
        title: "Operation Dudula",
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 20,
        title: <a href="https://pac.org.za/">Pan Africanist Congress of Azania (PAC)</a>,
        icon: "./Assets/Images/Party Logo/PAC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 21,
        title: <a href="https://mypa.org.za/">Patriotic Alliance (PA)</a>,
        icon: "./Assets/Images/Party Logo/PA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 22,
        title: <a href="https://pmc.org.za/">Peoples Movement For Change (PMC)</a>,
        icon: "./Assets/Images/Party Logo/PMC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 23,
        title: <a href="https://www.referendumparty.org/">Referendum Party (RP)</a>,
        icon: "./Assets/Images/Party Logo/RP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 24,
        title: <a href="https://www.risemzansi.org/">Rise Mzansi (RS)</a>,
        icon: "./Assets/Images/Party Logo/Rise-Mzansi.png",
        date: "08/02/2024",
        responsibility: [""],
    },
    {
        id: 25,
        title: <a href="https://sizweummahnation.wordpress.com/">Sizwe Ummah Nation (SUN)</a>,
        icon: "./Assets/Images/Party Logo/SUN.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 26,
        title: <a href="https://mkparty.org.za/">uMkhonto weSizwe (MK)</a>,
        icon: "./Assets/Images/Party Logo/MK.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 27,
        title: <a href="https://udm.org.za/">United Democratic Movement (UDM)</a>,
        icon: "./Assets/Images/Party Logo/UDM.png",
        date: "02/03/2024",
        responsibility: [""],
    },
    {
        id: 28,
        title: <a href="https://www.vfplus.org.za/">Freedom Front Plus (FF+)</a>,
        icon: "./Assets/Images/Party Logo/FF+.png",
        date: "02/03/2024",
        responsibility: [""],
    },
]

export const westernCape = WESTERN_CAPE.map(data => 
    <li key={data.id}>
      <p>{data.title}</p>
    </li>
  )