const National_Candidates = [
    {
        id: 42,
        title: <a href="https://www.risemzansi.org/">Rise Mzansi (RS)</a>,
        icon: "./Assets/Images/Party Logo/Rise-Mzansi.png",
        date: "08/02/2024",
        responsibility: ["The People’s Manifesto Booklet for South Africa is being launched on 20 January 2024. The manifesto focuses on policies such as leadership, governance, economy and jobs, individual and family well-being, and managing the climate crisis. The National Leadership Collective (NLC) of RISE Mzansi wishes to express gratitude to the thousands of people who attended their community meetings and made their voices heard. The goal of the manifesto is to ensure that all South Africans have access to the same rights, entitlements, and opportunities to create a united, equal, and prosperous society. The governing ANC has run out of ideas, goodwill, and steam, and needs people who will choose to lead ethically and professionally for the common good. The NLC will publish the names of candidates who represent South Africa in all its facets, have a track record, skills, and experience."],
    },
    {
        id: 28,
        title: <a href="https://effonline.org/">Economic Freedom Fighters (EFF)</a>,
        icon: "./Assets/Images/Party Logo/EFF.png",
        date: "08/02/2024",
        responsibility: ["The Economic Freedom Fighters (EFF) has released a manifesto for the 2024 general elections in South Africa, with a focus on land, jobs, and energy and electricity supply. The manifesto emphasizes the need to address landlessness and joblessness among black South Africans, who remain on the margins of economic production and participation. The government is unable to guarantee dependable and reliable electricity generation, transmission, and distribution, leading to a crisis of loadshedding. The EFF aims to address this by focusing on clear, implementable, and decisive programmes for all spheres of governance, including public safety, health, social development, and democracy. Despite 30 years of political inclusion, the EFF believes that South Africa is trapped in a vicious cycle of underdevelopment, underperformance, and an unpredictable future due to incompetent and visionless government.."],
    },
    {
        id: 26,
        title: <a href="https://www.da.org.za/">Democratic Alliance (DA)</a>,
        icon: "./Assets/Images/Party Logo/DA.png",
        date: "17/02/2024",
        responsibility: ["The DA has launched its election manifesto for South Africa in 2024, promising to rescue the country from unemployment, crime, corruption, service delivery, education, and public health systems. The party believes that by forging a common vision among opposition parties to unseat the current governing party, South Africans have a realistic chance of electing a new government. The DA has the size, experience, and track-record of good governance to serve as the anchor for a stable and successful multi-party government. Voting for the DA will not only ensure stability but will also help to propel the Multi-Party Charter beyond the magical threshold of 50%-plus-one required to form a new national government. If voters do not put the DA in a position to succeed, other parties, such as the EFF, may form a coalition of corruption with the ANC. Therefore, voting for a strong DA is a win-win proposition to prevent a coalition between the ANC and EFF from destroying South Africa."],
    },
    {
        id: 13,
        title: <a href="https://www.anc1912.org.za/">African National Congress (ANC)</a>,
        icon: "./Assets/Images/Party Logo/ANC.png",
        date: "24/02/2024",
        responsibility: ["The ANC's 2024 election manifesto outlines six priorities to transform the economy and create jobs in South Africa. The first priority is to put South Africans to work through a jobs plan, build industries to achieve an inclusive economy, tackle the high cost of living, invest in people, defend democracy, and build a better Africa and world. The National Development Plan aims to eliminate poverty and reduce inequality by 2030 as a collective responsibility. The party promises to correct mistakes, advance a better life for all, and ensure that no one is left behind. Over the next five years, the focus will be on six priorities, including job creation, infrastructure, social security, education, health care, defending democracy, fighting corruption, building the developmental state, and promoting nation-building and social cohesion. The ANC's vision and plan is to do more, todo more, and to do it faster. The country has achieved much, but there are forces that seek to undo the progress of democracy, so it is crucial to defend our hard-won freedom. By renewing the mandate of the ANC, we build on the foundations of the 30 years of freedom and ensure equal opportunities for all South Africans."],
    },
    {
        id: 48,
        title: <a href="https://udm.org.za/">United Democratic Movement (UDM)</a>,
        icon: "./Assets/Images/Party Logo/UDM.png",
        date: "02/03/2024",
        responsibility: ["The UDM 2024 manifesto for the National and Provincial Elections in South Africa highlights the need for good governance, investment in business confidence, sustainable job creation, and protecting the environment. The country is in crisis due to joblessness, crime, lawlessness, violence, poor economy, loadshedding, water, poverty, incompetence, and corruption. The ruling party has been implicated in corruption scandals, and the Tripartite Alliance partners have not delivered on their economic policies. The manifesto also highlights the role of traditional leaders, faith-based organizations, international relations, diversity, conservation, sustainability, safety, security, justice, defense, LGBTQIA+ community, education, healthcare, and marginalized stakeholders. The President warns that the ruling party cannot be trusted to find solutions to corruption as they have their hands in the proverbial cookie jar."],
    },
    {
        id: 50,
        title: <a href="https://www.vfplus.org.za/">Freedom Front Plus (FF+)</a>,
        icon: "./Assets/Images/Party Logo/FF+.png",
        date: "02/03/2024",
        responsibility: ["The building plan for South Africa offers solutions for building a better future, including multi-party governments, a free-market system, small, central and local governments, independent communities, and promoting communities' constitutional right to self-determination. The country is currently in decline due to political centralization, economic over-regulation, and environmental damage caused by the ANC's failed policy. The plan emphasizes the need for a better dispensation, not just a better government, and to restore the country's infrastructure and service. The focus of the plan is on restoring a new dispensation that prioritizes diversity, economic empowerment, and sustainable development and environmental sustainability. The National Democratic Revolution (NDR) and Black Economic Empowerment (BEE) are key to achieving this. South Africa needs to make a positive turning point in order to turn around its decline and restore its economy, infrastructure, and services. It will take years of hard work to undo the legacy of corruption and turn the country around."],
    },
    {
        id: 33,
        title: <a href="https://www.ifp.org.za/">Inkatha Freedom Party (IFP)</a>,
        icon: "./Assets/Images/Party Logo/IFP.png",
        date: "02/03/2024",
        responsibility: ["The Inkatha Freedom Party (IFP) has been a formidable opposition in South Africa for almost fifty years. The IFP believes that there is no secure future for one segment of society while another remains disempowered. To fund their programmes, the IFP will embark on a robust restructuring of the economy and the fiscus. The party believes in the principles of ubuntu/botho and integrity, and they invite voters to vote for a new government. The election will have a far-reaching impact, affecting this generation and beyond."],
    },
    {
        id: 17,
        title: <a href="https://www.aljama.co.za/">Al-Jama-ah (AJA)</a>,
        icon: "./Assets/Images/Party Logo/ALJ.jpg",
        date: "02/03/2024",
        responsibility: ["The Al-Jamaa political party in South Africa has presented its vision for a better future for all in 2024. It promises to ensure full employment, equal rights for every child, personal safety, clean water, universal health care, and effective civilian oversight of the intelligence sector. The party pursues values-based policies and ethical approaches to everything it does, and has earned the respect of both ruling and opposition parties. It has gained the confidence of rural and urban communities through its ability to carry out innovative and inclusive development projects and fight for quality services for all. Al Jama-ah has demonstrated notable success in Gauteng's City of Johannesburg, where an Executive Mayor, Kabelo Gwamanda, has held the position of Executive Mayor since the early part of 2023. The Party pursues an inclusive human rights-centered society and aims to dismantle cultural, systemic, institutional, and structural barriers to the growth and development of children and youth. It will continue to fight against all forms of oppression and injustice and address democratic discontent. The President of the Party has made an indelible mark as a small but trustworthy political party with a growing presence in all three spokespersons of government. In the past five years, the party has demonstrated that it is worthy of the confidence and trust of the public through its Members of Parliament, Members of the Provincial Legislature, and its councillors."],
    },
    {
        id: 32,
        title: <a href="https://forgood.org.za/">GOOD</a>,
        icon: "./Assets/Images/Party Logo/GOOD.png",
        date: "15/03/2024",
        responsibility: [" The Good Party's manifesto for the 2024 National General Election in South Africa focuses on four pillars: social justice, economic justice, spatial justice, and environmental justice. The party aims to create a fair and equal society, eradicate poverty, ensure access to quality basic services, reform education and skills development, promote inclusive economic growth, reduce income inequality, address gender-based violence, and ensure environmental sustainability. Specific proposals include implementing a R999 basic income grant, upgrading core public infrastructure, improving access to early childhood development, creating safe and inclusive environments for the LGBTQIA+ community, and addressing the spatial legacy of apartheid through land reform, housing for all, and quality public transportation. The party also prioritizes combating climate change, ending load-shedding through renewable energy, and stopping corruption in government."],
    },
    {
        id: 7,
        title: <a href="https://acdp.org.za/">African Christian Democratic Party (ACDP)</a>,
        icon: "./Assets/Images/Party Logo/ACDP.png",
        date: "15/03/2024",
        responsibility: ["  The African Christian Democratic Party (ACDP) has released its manifesto for the 2024 National and Provincial Elections in South Africa. The manifesto outlines the party's policy proposals for various issues, including job creation, poverty alleviation, energy and logistics, crime and justice, property rights, rural development and food security, access to housing, clean water and sanitation, education and skills development, health and well-being, Israel, protecting and promoting freedoms, family values, and moral regeneration. The ACDP supports an open market economy with a social conscience to address high levels of unemployment, poverty, and inequality. The party proposes creating an enabling environment for businesses through infrastructure development, a professional public service, good education and vocational training, and supporting small businesses through mentoring, start-up funding, skills development, and training. The ACDP also aims to remove policy uncertainty, such as that relating to land, to attract foreign direct investment and end cadre deployment and race-based policies that have caused inflated prices and resulted in a tender-preneur economy."],
    },
    {
        id: 4,
        title: <a href="https://www.actionsa.org.za//">Action SA()</a>,
        icon: "./Assets/Images/Party Logo/ASA.png",
        date: "15/03/2024",
        responsibility: ["ActionSA has unveiled its manifesto for the 2024 national and provincial elections in South Africa. The manifesto aims to address pressing issues such as inequality, unemployment, and poor education outcomes. The approach focuses on nine priority intervention areas, including economic growth, small business development, and international trade reform. ActionSA believes the manifesto offers a credible alternative to the failed politics of the establishment. South Africans are encouraged to take action by voting for an alternative that prioritizes the needs of their people"],
    },
    {
        id: 46,
        title: <a href="https://mkparty.org.za/">uMkhonto weSizwe (MK)</a>,
        icon: "./Assets/Images/Party Logo/MK.png",
        date: "15/03/2024",
        responsibility: ["The MK Party manifesto outlines their commitment to bringing transformative change to South African society, addressing issues such as economic inequality, access to education and healthcare, national security, land distribution, traditional leadership, and foreign policy. They aim to extend the electrical grid, prioritize sustainable electricity, align education with market demands, enhance skills transfer, strengthen border security, overhaul law enforcement, accelerate land redistribution, protect resources, improve healthcare access and quality, create jobs, promote economic growth, empower traditional leaders, advocate for regional stability, and assert South Africa's interests on the international stage. The manifesto reflects the party's vision for a more equitable, secure, and prosperous future for all South Africans through collaborative efforts, sound governance, and commitment to their principles."],
    },
    {
        id: 22,
        title: <a href="https://www.bosa.co.za/">Build One South Africa (BOSA)</a>,
        icon: "./Assets/Images/Party Logo/BOSA.png",
        date: "15/03/2024",
        responsibility: ["The BOSA Jobs Plan is a comprehensive blueprint for building a South Africa that works, focusing on creating jobs, growing the economy, reforming education, building a capable state, and creating a safe environment. BOSA values equality, unity, dignity, and community, and is led by a diverse team with experience in various fields. The plan aims to address the high levels of unemployment, especially among the youth, by overhauling the education system, stimulating employment in marginalized communities, supporting advanced sectors of the economy, and investing in small and medium-sized enterprises. The plan also tackles the energy crisis, reforming the education system to prepare young people for the workforce, building a capable state, and addressing gender-based violence.Overall, BOSA's Jobs Plan offers a holistic approach to building a prosperous and safe South Africa."],
    },
    {
        id: 38,
        title: <a href="https://pac.org.za/">Pan Africanist Congress of Azania (PAC)</a>,
        icon: "./Assets/Images/Party Logo/PAC.png",
        date: "15/03/2024",
        responsibility: ["The Pan Africanist Congress of Azania (PAC) has released its manifesto for the 2024 national and provincial elections in South Africa. The manifesto focuses on building a democratic state, achieving total liberation, and restoring the land to its original owners. The PAC is committed to complying with the Basic Documents, including the Constitution, Disciplinary Code, and the 1959 Africanist Manifesto. They are contesting for power and influence in the running of the country and are sticking to the concrete ideas of Sobukwe and APMda. The 2024 Manifesto is an outcome of collective work by various leaders at national, provincial, regional, and branch levels. The PANDA's development agenda includes focusing on economic growth, gender equality, national security, border management, agriculture, education, health, social development, transportation, sports, arts, culture, and more. The Manifesto aims to help the liberation movement to speak with one voice and achieve total liberation."],
    },
    {
        id: 16,
        title: <a href="http://www.atm-sa.co.za/">African Transfomation Movement (ATM)</a>,
        icon: "./Assets/Images/Party Logo/ATM.png",
        date: "15/03/2024",
        responsibility: ["The African National Action Party (ATM) is a faith-based political party that aims to create a healthy, functional, democratic, fair, and prosperous society in South Africa. Its political ideology is Humanism and African Humanism. Its aims include offering a good quality of life to all citizens, promoting Ubuntu, African languages, religious diversity, unity, and patriotism. It also advocates for social justice and equality, fostering innovation and technological advancements, and promoting good governance, transparency, and accountability in all sectors of society. The party encourages citizen participation in the democratic process, collaboration with non-governmental organizations, community groups, and other stakeholders to address social issues, provide social services, and empower local communities. The political principle of the ATM is to transform South Africa into a peaceful, prosperous, and economically viable state that upholds ethical leadership, accountability, and anticorruption measures. It promotes inclusivity, regardless of race, gender, sexual orientation, or other personal characteristics, fostering a culture of acceptance and respect."],
    },
    {
        id: 37,
        title: <a href="https://ohm.org.za/">Organic Humanity Movement (OHM)</a>,
        icon: "./Assets/Images/Party Logo/OHM.png",
        date: "15/03/2024",
        responsibility: ["The South African political system is failing to address the problems of poverty, education, radical elements, overpopulated cities, shrinking economy, and crumbling infrastructure. The global governance system robs nations of their autonomy and strength, leading to instability, supply issues, economic decline, and the threat of war. The current political system stifles progress and weakening South Africa. To achieve a vision of individual liberty and national sovereignty, the political system needs to be reformed to make South Africa a strong, independent nation. The Organization of the People of the Year (OHM) is a volunteer-based movement that aims to empower individuals and communities through education, knowledge, and action. They believe citizens should expect functional, well-maintained, and expanding infrastructure, protection of natural resources, preservation of the environment, security of borders, positive and beneficial international relations, and service delivery administered almost entirely by local government. OHM works on a volunteer basis and relies on small and regular donations from members and supporters. Their vision for South Africa is to empower people with knowledge and skills required to succeed in their lives, free from government and corporate interference, and liberated from the shackles of current systems. They envision a country free of racial classification and discrimination, where each citizen identifies as South African, united by a desire to remain free from global and governmental control while building a nation we can all be proud of."],
    },
    {
        id: 21,
        title: <a href="https://azapo.org.za/">Azanian Peoples Organization (AZAPO)</a>,
        icon: "./Assets/Images/Party Logo/AZAPO.jpeg",
        date: "15/03/2024",
        responsibility: ["AZAPO's 2024 Elections Manifesto outlines their goals and plans if they were to come into power. The manifesto is divided into several sections, each focusing on a different area of concern. The first section discusses empowering citizens and promoting a non-racial and non-sexist society. The second section outlines creating a leaner and more efficient government. The third section covers fighting crime, corruption, and nepotism. The fourth section focuses on economic development, job creation, and delivering basic services. The fifth section covers social welfare and community development. The sixth section emphasizes regulating immigration and protecting jobs and resources. The document concludes with a call to action for South Africans to vote for AZAPO in the upcoming elections."],
    },
    {
        id: 24,
        title: <a href="https://www.copesa.org.za/">Congress of the People (COPE)</a>,
        icon: "./Assets/Images/Party Logo/COPE.png",
        date: "15/03/2024",
        responsibility: ["The Congress of the People is a social democratic party in South Africa that believes in a society that values justice. Their mission is to govern for a continuously improving quality of life for the majority, especially the poor and marginalised. The manifesto sets out issues that must be agreed upon by all political parties and endorsed by a large body of voters. Voters must use this manifesto to engage political representatives and strengthen political oversight mechanisms."],
    },
    {    
        id: 0,
        title: <a href="https://www.hope4sa.org.za/">#HOPE4SA</a>,
        icon: "./Assets/Images/Party Logo/H4SA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 1,
        title: "Abantu Batho Congress (ABC)",
        icon: "./Assets/Images/Party Logo/ABC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 2,
        title: "Able Leadership (AL)",
        icon: "./Assets/Images/Party Logo/AL.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 3,
        title: <a href="https://aadp.org.za/">Action Alliance Development Party (AADP)</a>,
        icon: "./Assets/Images/Party Logo/AADP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 5,
        title: "Africa Africans Reclaim (AAR)",
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 6,
        title: <a href="https://ara-sa.org.za/">Africa Restoration Alliance (ARA)</a>,
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 8,
        title: <a href="https://actonline.org.za/">African Congress for Transfomation (ACT)</a>,
        icon: "./Assets/Images/Party Logo/ACT.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 9,
        title: <a href="https://acmovement.org.za/">African Content Movement (ACM)</a>,
        icon: "./Assets/Images/Party Logo/ACM.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 10,
        title: <a href="https://africanheart.org.za/">African Heart Congress (AHC)</a>,
        icon: "./Assets/Images/Party Logo/AHC.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 11,
        title: "African Independent Congress (AIC)",
        icon: "./Assets/Images/Party Logo/AIC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 12,
        title: <a href="https://africanmovementcongress.org/#:~:text=AFRICAN%20MOVEMENT%20CONGRESS%20is%20a,racism%20and%20the%20class%20enemy.">African Movement Congress (AMC)</a>, 
        icon: "./Assets/Images/Party Logo/AMC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 14,
        title: "African Peoples Convention (APC)",
        icon: "./Assets/Images/Party Logo/APC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 15,
        title: "African Peoples Movement (APM)",
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 18,
        title: "All Citizens Party",
        icon: "./Assets/Images/Party Logo/ACP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 19,
        title: <a href="https://accforchange.org.za/">Alliance of Citizens For Change</a>,
        icon: "./Assets/Images/Party Logo/A.C.C.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 20,
        title: <a href="https://am4c.org/">Allied Movement for Change (AM4C)</a>,
        icon: "./Assets/Images/Party Logo/AM4C.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 23,
        title: "Citizans",
        icon: "./Assets/Images/Party Logo/CITIZANS.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    
    {
        id: 25,
        title: "Conservatives in Action (CIASA)",
        icon: "./Assets/Images/Party Logo/CIA.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 27,
        title: <a href="https://dlc.org.za/">Democratic Liberal Congress (DLC)</a>,
        icon: "./Assets/Images/Party Logo/DLC.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 29,
        title: <a href="https://www.elfonline.org/">Economic Liberators Forum South Africa (ELF-SA)</a>,
        icon: "./Assets/Images/Party Logo/ELF-SA.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 30,
        title: <a href="https://forum4sd.org.za/">Forum 4 Service Delivery (F4SD)</a>,
        icon: "./Assets/Images/Party Logo/F4SD.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 31,
        title: "Free Democrats (FREE DEMS)",
        icon: "./Assets/Images/Party Logo/FREE DEMS.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 34,
        title: <a href="https://nationalcolouredcongress.org.za/">National Coloured Congress (NCC)</a>, 
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 35,
        title: "National Freedom Party (NFP)",
        icon: "./Assets/Images/Party Logo/NFP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 36,
        title: <a href="https://nccm.org.za/">Northern Cape Communities Movement (NCCM)</a>,
        icon: "./Assets/Images/Party Logo/NCCM.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 39,
        title: <a href="https://mypa.org.za/">Patriotic Alliance (PA)</a>,
        icon: "./Assets/Images/Party Logo/PA.png",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 40,
        title: <a href="https://pmc.org.za/">Peoples Movement For Change (PMC)</a>,
        icon: "./Assets/Images/Party Logo/PMC.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 41,
        title: <a href="https://www.referendumparty.org/">Referendum Party (RP)</a>,
        icon: "./Assets/Images/Party Logo/RP.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 43,
        title: <a href="https://sizweummahnation.wordpress.com/">Sizwe Ummah Nation (SUN)</a>,
        icon: "./Assets/Images/Party Logo/SUN.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 44,
        title: "South African Rainbow Alliance (SARA)",
        icon: "./Assets/Images/Party Logo/SARA.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 45,
        title: <a href="https://sarko.org.za/about/">South African Royal Kingdoms Organization (SARKO)</a>,
        icon: "./Assets/Images/Party Logo/SARKO.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 47,
        title: <a href="https://www.uat2023.org/">United Africans Transformation (UAT) </a>,
        icon: "",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 49,
        title: <a href="https://uimsa.org/">United Independent Movement (UIM)</a>,
        icon: "./Assets/Images/Party Logo/AAR.jpg",
        date: "15/03/2024",
        responsibility: [""],
    },
    {
        id: 51,
        title: "Xiluva",
        icon: "./Assets/Images/Party Logo/Xiluva.jfif",
        date: "15/03/2024",
        responsibility: [""],
    },
]


export const SortedData = National_Candidates.sort(function (a, b) {
    if (a.id < b.id) {
        return -1;
    }
    if (a.id > b.id) {
        return 1;
    }
    return 0;
})



